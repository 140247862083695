import {getApiRequest, postApiRequest} from "../index";
import {UserDashboardPayment} from "../../model/Payment";

export const listUserTodayPaymentsApiRoute = async () => {
    return (await getApiRequest(`me/stats/payments`)) as UserDashboardPayment[];
};

export interface CreatePaymentRequest {
    paymentLinkId: string;
    amount: string;
    totalAmount: string; // amount with fee
    includesFee: boolean; // was fee active or not, different request for backend
    commentOnly: boolean  // if true set amount to zero and totalAmount, no calculate exchange rated
    comment?: string;
    paymentReceiver?: string;
    rating?: number;
    ip: string;
    payer?: string; // for OVO payment, OVO ID - phone number, additional field for IDR
}

export interface CreatePaymentResponse {
    paymentUrl: string;
}

export const postPaymentRoute = async (
    request: CreatePaymentRequest
) => {
    return (await postApiRequest(
        "payments/card",
        request
    )) as CreatePaymentResponse;
};

export const postApplePayPaymentRoute = async (
    request: CreatePaymentRequest
) => {
    return (await postApiRequest(
        "payments/apple",
        request
    )) as CreatePaymentResponse;
};

export const postGooglePayPaymentRoute = async (
    request: CreatePaymentRequest
) => {
    return (await postApiRequest(
        "payments/google",
        request
    )) as CreatePaymentResponse;
};

export const postOVOPayPaymentRoute = async (
    request: CreatePaymentRequest
) => {
    return (await postApiRequest(
        "payments/ovo",
        request
    )) as CreatePaymentResponse;
};

export interface CreatePaymentCommentRequest {
    id: string;
    pay: string;
    comment?: string;
    rating?: number;
}

export const postPaymentCommentRoute = async (
    request: CreatePaymentCommentRequest
) => {
    return (await postApiRequest("payments/comment", request));
};

export const postSBPPaymentRoute = async (
    request: CreatePaymentRequest
) => {
    return (await postApiRequest(
        "payments/sbp",
        request
    )) as CreatePaymentResponse;
};

export interface PaymentReviewLinkResponse{
    reviewLink?: string;
}

export const getPaymentReviewLinkApiRoute = async (payId: string) => {
    return (await getApiRequest(`payments/${payId}/review-link`)) as PaymentReviewLinkResponse;
};

export interface OVOPaymentStatusResponse {
    status: string;
    externalID: string;
    paymentSuccessLink: string;
    paymentFailLink: string;
}

export const getOVOPayStatusApiRoute = async (payId: string) => {
    return (await getApiRequest(`payments/ovo/${payId}/status`)) as OVOPaymentStatusResponse;
};

export const postDokuCardPaymentRoute = async (
    request: CreatePaymentRequest
) => {
    return (await postApiRequest(
        "payments/doku/card",
        request
    )) as CreatePaymentResponse;
};
