export const UserAccountTypeIndividual = "individual";
export const UserAccountTypeOrganization = "organization";
export type UserAccountType = "individual" | "organization";

export const IdentityVerificationStatusNotInitiated = "not_initiated";
export const IdentityVerificationStatusInProcess = "in_process";
export const IdentityVerificationStatusSuccess = "success";
export const IdentityVerificationStatusFailure = "failure";

export default interface User {
    id: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    accountType: UserAccountType;
    avatar?: string;
    didConfirmEmail: boolean;
    identityVerificationStatus: string;
    amlCheck: boolean;
    tipsCheck: boolean;
    payTheme: string;
    lang: string;
    balanceCurrency: string;
    whatsappPhone?: string;
    reviewLink?: string;
}

export const formatUserName = (user: User) => {
    return user.firstName + " " + user.lastName;
};

export const formatUserNameWithDefault = (user: User, def: string | undefined) => {
    if (def) return def;
    else return formatUserName(user);
}

export const formatUserInitialsForAvatar = (user: User) => {
    let result = "";
    if (user.firstName) {
        result += user.firstName!.toUpperCase()[0];
    }
    if (user.lastName) {
        result += user.lastName!.toUpperCase()[0];
    }
    return result;
};

export interface EstablishmentUserInfo {
    id: string;
    email: string;
    phoneNumber: string;
    password: string;
    firstName: string;
    lastName: string;
    company: string;
    industry: string;
    country: string;
    countryCode: string;
    profession: string;
    establishmentLogo: string;
    auth?: string;
    me?: User;
}

export interface Achievement {
    id: string;
    textKey: string;
    img: string;
    groupID: string;
    createdAt: string;
}
