import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useState, lazy} from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import useRootStore from "../hooks/useRootStore";
import InitialLoading from "./shared/InitialLoading";
import {ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NotFoundPage from "./pages/NotFoundPage";
import mainTheme from "./themes/mainTheme";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentErrorPage from "./pages/PaymentErrorPage";
import {googleTagManagerInit} from "./shared/GoogleAnalyticsHandler";
import {i18nUsers} from './locale/i18n';
import PaymentCommentPage from "./pages/PaymentCommentPage";
import PaymentWaitPage from "./pages/PaymentWaitPage";

const PaymentPage = lazy(() => import("./pages/PaymentPage"));
const EmailConfirmationPage = lazy(() => import("./pages/EmailConfirmationPage"));
const UserInviteViewPage = lazy(() => import("./pages/UserInviteViewPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const SignUpPage = lazy(() => import("./pages/SignUpPage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));
const ConfirmForgotPasswordPage = lazy(() => import("./pages/ConfirmForgotPasswordPage"));
const PaymentAPIPage = lazy(() => import("./pages/PaymentAPIPage"));
const SignUpAPIPage = lazy(() => import("./pages/SignUpAPIPage"));
const LoggedInLayout = lazy(() => import("./layouts/LoggedInLayout"));

googleTagManagerInit();

const App = observer(() => {

    const activeUserStore = useRootStore().activeUserStore;

    const [isLoadingMe, setIsLoadingMe] = useState<boolean>(true);

    const loadAboutMe = useCallback(async () => {
        try {
            await activeUserStore.fetch();
            if (activeUserStore.me) {
                await i18nUsers.changeLanguage(activeUserStore.me.lang)
            }
        } catch (error) {
            activeUserStore.clear();
        }
        setIsLoadingMe(false);
    }, [activeUserStore]);

    useEffect(() => {
        setTimeout(() => {
            if (activeUserStore.jwt) {
                loadAboutMe().then();
            } else {
                setIsLoadingMe(false);
            }
        }, 300);
    }, [loadAboutMe, activeUserStore.jwt]);

    return (
        <ThemeProvider theme={mainTheme}>
            <CssBaseline/>
                {isLoadingMe ? (
                    <InitialLoading/>
                ) : !activeUserStore.me ? (
                    <Router>
                        <Switch>
                            <Route path="/login" exact component={LoginPage}/>
                            <Route path="/sign-up" exact component={SignUpPage}/>
                            <Route path="/forgot-password" exact component={ForgotPasswordPage}/>
                            <Route path="/confirm-forgot-password" exact component={ConfirmForgotPasswordPage}/>
                            <Route path="/pay/:id" exact component={PaymentPage}/>
                            <Route path="/pay-api/:id" exact component={PaymentAPIPage}/>
                            <Route path="/sign-up-api" exact component={SignUpAPIPage}/>
                            <Route
                                path="/confirm-email"
                                exact
                                component={EmailConfirmationPage}
                            />
                            <Route path="/accept-invite" exact component={UserInviteViewPage}/>
                            <Route
                                path="/payment-success"
                                exact
                                component={PaymentSuccessPage}
                            />
                            <Route
                                path="/payment-success-gorillas"
                                exact
                                render={(props) => <PaymentSuccessPage {...props} paymentFormTheme={"gorillas"}/>}
                            />
                            <Route
                                path="/payment-success-gophr"
                                exact
                                render={(props) => <PaymentSuccessPage {...props} paymentFormTheme={"gophr"}/>}
                            />
                            <Route
                                path="/payment-success-comment-getwifi/:id/:pay"
                                exact
                                component={PaymentCommentPage}
                            />
                            <Route path="/payment-error" exact component={PaymentErrorPage}/>
                            <Route path="/payment-wait"  exact component={PaymentWaitPage}/>
                            <Route path="/not-found" exact component={NotFoundPage}/>
                            <Redirect from="/" exact to="/login"/>
                            <Redirect from="*" to="/not-found"/>
                        </Switch>
                    </Router>
                ) : (
                    <Router>
                        <Switch>
                            <Route path="/pay/:id" exact component={PaymentPage}/>
                            <Route path="/confirm-email" component={EmailConfirmationPage}/>
                            <Route path="/accept-invite" component={UserInviteViewPage}/>
                            <Route
                                path="/payment-success"
                                exact
                                component={PaymentSuccessPage}
                            />
                            <Route
                                path="/payment-success-gorillas"
                                exact
                                render={(props) => <PaymentSuccessPage {...props} paymentFormTheme={"gorillas"}/>}
                            />
                            <Route
                                path="/payment-success-gophr"
                                exact
                                render={(props) => <PaymentSuccessPage {...props} paymentFormTheme={"gophr"}/>}
                            />
                            <Route
                                path="/payment-success-comment-getwifi/:id/:pay"
                                exact
                                component={PaymentCommentPage}
                            />
                            <Route path="/payment-error" exact component={PaymentErrorPage}/>
                            <Route path="/payment-wait"  exact component={PaymentWaitPage}/>
                            <Route path="/not-found" component={NotFoundPage}/>
                            <Route component={LoggedInLayout}/>
                        </Switch>
                    </Router>
                )}
        </ThemeProvider>
    );
});

export default App;
